import '../polyfills';
import '../theme/global.css';
import Head from 'next/head';
import getConfig from 'next/config';
import Router, { useRouter } from 'next/router';
import * as Sentry from '@sentry/browser';
import { ApolloProvider } from '@apollo/client';
import dynamic from 'next/dynamic';
import { appWithTranslation, useTranslation } from 'next-i18next';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { AppCacheProvider } from '@mui/material-nextjs/v15-pagesRouter';
import nProgress from 'nprogress';
import ProvenExpertBar from '@components/organisms/ProvenExpertBar';
import WorkshopFab from '@components/molecules/WorkshopFab';
import theme, { hyundaiTheme, mitsubishiTheme, zIndices } from '../theme';
import Footer from '../components/organisms/Footer';
import Header from '../components/organisms/Header';
import ProvenExpertContext from '../context/ProvenExpertContext';
import WhatsappFab from '../components/molecules/WhatsappFab';
import ScrollToTopFab from '../components/molecules/ScrollToTopFab';
import FloatingActionBar from '../components/organisms/FloatingActionBar';
import GdprConsent from '../components/organisms/GdprConsent';
import { useApollo } from '@/state';

// setLocale(de);
Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const GlobalNotice = dynamic(() => import('../components/organisms/GlobalNotice'), { ssr: false });
const Tracking = dynamic(() => import('../components/organisms/Tracking'), { ssr: false });

const { publicRuntimeConfig } = getConfig();

if (publicRuntimeConfig.SENTRY_DSN) {
  // https://leerob.io/blog/configuring-sentry-for-nextjs-apps
  Sentry.init({
    enabled: !publicRuntimeConfig.isDev,
    dsn: publicRuntimeConfig.SENTRY_DSN,
  });
}

function App(props) {
  const {
    Component,
    pageProps,
  } = props;
  const graphQlClient = useApollo(pageProps.initialGraphQlState);
  const { i18n } = useTranslation(['common']);
  const router = useRouter();

  useEffect(() => {
    const handler = (event) => {
      setTimeout(() => {
        const hash = event.newURL.split('#')[1];

        if (hash) {
          const element = document.getElementById(hash.substring(7));

          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 500);
    };

    setTimeout(() => {
      window.addEventListener('hashchange', handler);
      handler({
        newURL: window.location.href,
      });
    }, 1000);

    return () => {
      window.removeEventListener('hashchange', handler);
    };
  }, [router.asPath]);

  useEffect(() => {
    i18n.changeLanguage(router.locale);
  }, [router.locale]);

  const {
    appData,
    pageHeadConfig,
    pageBottomConfig,
    isGlobalNoticeHidden,
  } = pageProps;
  const siteConfig = appData.siteConfig.data?.attributes ?? {};
  const siteNotice = appData.siteNotice.data.attributes;
  const {
    provenExpertGoogleStars,
    renderBreadcrumbPath,
    renderBreadcrumbPathForSlug,
  } = appData;
  let currentTheme = theme;
  let cssOverride = '';

  if (router.asPath) {
    if (router.asPath.startsWith('/hyundai')) {
      currentTheme = hyundaiTheme;
      cssOverride = `
      .brand_logo_stylized,
      .mitsubishi_tag,
      .mgmotor_tag,
      .fuso_tag,
      .maxus_tag,
      .tropos_tag {
        display: none !important;
      }

      ${theme.breakpoints.up('md')} {
        .hyundai_tag.brand_logo_origin {
          display: block !important;
        }
      }
      `;
    } else if (router.asPath.startsWith('/mitsubishi')) {
      currentTheme = mitsubishiTheme;
      cssOverride = `
        .brand_logo_stylized,
        .hyundai_tag,
        .mgmotor_tag,
        .fuso_tag,
        .maxus_tag,
        .tropos_tag {
          display: none !important;
        }

        ${theme.breakpoints.up('md')} {
          .mitsubishi_tag.brand_logo_origin {
            display: block !important;
          }
        }
      `;
    } else if (router.asPath.startsWith('/fuso')) {
      currentTheme = mitsubishiTheme;
      cssOverride = `
        .brand_logo_stylized,
        .hyundai_tag,
        .mitsubishi_tag,
        .mgmotor_tag,
        .maxus_tag,
        .tropos_tag {
          display: none !important;
        }

        ${theme.breakpoints.up('md')} {
          .fuso_tag.brand_logo_origin {
            display: block !important;
          }
        }
      `;
    } else if (router.asPath.startsWith('/mg-motor')) {
      currentTheme = mitsubishiTheme;
      cssOverride = `
        .brand_logo_stylized,
        .hyundai_tag,
        .mitsubishi_tag,
        .fuso_tag,
        .maxus_tag,
        .tropos_tag {
          display: none !important;
        }

        ${theme.breakpoints.up('md')} {
          .mgmotor_tag.brand_logo_origin {
            display: block !important;
          }
        }
      `;
    } else if (router.asPath.startsWith('/maxus')) {
      currentTheme = mitsubishiTheme;
      cssOverride = `
        .brand_logo_stylized,
        .hyundai_tag,
        .mitsubishi_tag,
        .mgmotor_tag,
        .fuso_tag,
        .tropos_tag {
          display: none !important;
        }

        ${theme.breakpoints.up('md')} {
          .maxus_tag.brand_logo_origin {
            display: block !important;
          }
        }
      `;
    }
  }

  const statusCode = pageProps.statusCode ?? 200;
  let canonicalUrl = router.asPath.split('#')[0].split('?')[0];
  canonicalUrl = (router.locale !== publicRuntimeConfig.DEFAULT_LOCALE ? `/${router.locale}` : '') + canonicalUrl;
  canonicalUrl = `https://www.schimmel-automobile.de${canonicalUrl}`;
  const germanUrl = appData.pageUrls?.find((pageUrl) => pageUrl.locale === 'de');

  if (pageProps.canonicalUrl) {
    canonicalUrl = pageProps.canonicalUrl;

    if (canonicalUrl.startsWith('/')) {
      canonicalUrl = `https://www.schimmel-automobile.de${canonicalUrl}`;
    }
  }

  return (
    <AppCacheProvider {...props}>
      <Head>
        <meta charSet="utf-8" />
        {/* <link href="https://analytics.schimmel-automobile.de" rel="preconnect" /> */}
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="nVauIjomXFtphX_iF8DA4x2tOghkEvS4NuV4xsL0WEo" name="google-site-verification" />
        <meta content="notranslate" name="google" />
        <meta content="website" property="og:type" />
        <meta key="og:image" content="/images/og-image-default.png" property="og:image" />
        {statusCode === 200 && ([
          <link key="canonical" href={canonicalUrl} rel="canonical" />,
          <meta key="og:url" content={canonicalUrl} property="og:url" />,
        ])}
        <link
          href="/favicon.ico"
          rel="icon"
          sizes="16x16"
          type="image/x-icon"
        />
        <link href="/site.webmanifest" rel="manifest" />
        {appData.pageUrls?.length > 0 && (
          <>
            <link
              key="x-default"
              href={germanUrl.url.startsWith('/') ? `https://www.schimmel-automobile.de${germanUrl.url}` : germanUrl.url}
              hrefLang="x-default"
              rel="alternate"
            />
            {appData.pageUrls?.map((pageUrl) => (
              <link
                key={pageUrl.id}
                href={pageUrl.url.startsWith('/') ? `https://www.schimmel-automobile.de${pageUrl.url}` : pageUrl.url}
                hrefLang={pageUrl.locale}
                rel="alternate"
              />
            ))}
          </>
        )}
      </Head>
      {cssOverride && (
        <Head>
          <style
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cssOverride,
            }}
          />
        </Head>
      )}
      {router.asPath.startsWith('/hyundai') && (
        <Head>
          <link href="/fonts/hyundai/font.css" rel="stylesheet" />
        </Head>
      )}
      {router.asPath.startsWith('/mitsubishi') && (
        <Head>
          <link href="/fonts/mmc/font.css" rel="stylesheet" />
        </Head>
      )}
      {/* <GoogleTagManager
        gtmId={GTM_ID}
        gtmScriptUrl="https://analytics.schimmel-automobile.de/gtm.js"
      /> */}
      <ThemeProvider theme={currentTheme}>
        <ApolloProvider client={graphQlClient}>
          <CssBaseline />
          <Header
            breadcrumbs={[...renderBreadcrumbPath, ...renderBreadcrumbPathForSlug]}
            config={pageHeadConfig}
            data={appData}
          />
          <ProvenExpertContext.Provider value={provenExpertGoogleStars}>
            <Component {...pageProps} />
          </ProvenExpertContext.Provider>
          <Footer config={pageBottomConfig} data={appData} />
          <GdprConsent data={appData} />
          <FloatingActionBar data={siteConfig} />
          <ScrollToTopFab />
          <WhatsappFab phoneNumbers={siteConfig.whatsappNumbers ?? []} />
          <WorkshopFab data={siteConfig} />
          <ProvenExpertBar />
          <Tracking />
          <GlobalNotice data={siteNotice} isGlobalNoticeHidden={isGlobalNoticeHidden} />
        </ApolloProvider>
      </ThemeProvider>
      <style global jsx>
        {`
          body {
            transition: background-color 250ms ease-in-out;
            color: ${currentTheme.palette.text.primary} !important;
            background-color: ${currentTheme.palette.secondary.main} !important;
          }

          p a {
            color: ${currentTheme.palette.primary.main};
            text-decoration: none !important;
            background-image: linear-gradient(to right, ${currentTheme.palette.primary.main} 25%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 4px 1px;
            background-repeat: repeat-x;
          }

          p a:focus, p a:hover {
            color: ${currentTheme.palette.primary.dark};
            background-image: linear-gradient(to right, ${currentTheme.palette.primary.dark} 25%, ${currentTheme.palette.primary.dark} 0%);
          }

          #nprogress .bar {
            background-color: #FF9900;
            height: 4px;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: ${zIndices.nProgress};
          }
        `}
      </style>
    </AppCacheProvider>
  );
}

export default appWithTranslation(App);
