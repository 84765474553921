import { Breadcrumbs as MuiBreadcrumbs, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Link from '../atoms/Link';

export default function Breadcrumbs({ data }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const items = [
    {
      id: 'home',
      title: 'HOME',
      url: '/',
    },
    ...data,
  ];

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      sx={{
        '& a': {
          color: theme.palette.primary.main,
        },
        '& a:focus, & a:hover': {

        },
      }}
    >
      {items.map((item, index) => (index < items.length - 1
        ? (
          <Link key={item.id} href={item.url}>
            <Typography component="a" variant="caption">
              {renderTitle(item.title, t)}
            </Typography>
          </Link>
        )
        : (
          <Typography key={item.id} variant="caption">
            {renderTitle(item.title, t)}
          </Typography>
        )))}
    </MuiBreadcrumbs>
  );
}

function renderTitle(title, t) {
  if (title === 'HOME') {
    return t('components.molecules.Breadcrumbs.homePage');
  }

  if (title.includes('#')) {
    // eslint-disable-next-line prefer-destructuring,no-param-reassign
    title = title.split('#')[0];
  }

  return title;
}
