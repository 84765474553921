import NativeLink from 'next/link';
import React from 'react';
import { useTranslation } from 'next-i18next';

export default function Link(props) {
  const {
    children,
    href,
    passHref,
  } = props;
  // const { i18n } = useTranslation();
  const linkProps = {
    ...props,
    passHref: passHref ?? true,
  };

  if (linkProps.href === '#') {
    linkProps.href = {
      pathname: '#',
    };
  }

  /* const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }

    if (e.defaultPrevented) {
      return;
    }

    e.preventDefault();
    window.location.href = getUrl(href, i18n);
  }; */

  return (
    <NativeLink
      href={href}
      legacyBehavior
      {...linkProps}
    >
      {children}
    </NativeLink>
  );
}

Link.propTypes = {
  ...NativeLink.propTypes,
};

function getUrl(url, i18n) {
  return `${i18n.language === 'de' ? '' : `/${i18n.language}`}${url}`;
}
