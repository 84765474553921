import {
  Box, Typography, useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import useContentElement from '../../hooks/useContentElement';

export default function RibbonElement(props) {
  const { data, ...otherProps } = props;
  const theme = useTheme();

  const { elementData } = useContentElement(
    data,
    RibbonElement.dataSchema,
  );

  const {
    text,
    theme: themeOverride,
    position_y,
  } = elementData;

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}
    >
      <Box sx={{ height: `${position_y ?? 80}%` }} />
      <Box
        sx={{
          maxWidth: '80%',
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          backgroundColor: themeOverride === 'hyundai' ? '#012c5f' : theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
        {...otherProps}
      >
        <Typography
          component="div"
          dangerouslySetInnerHTML={{
            __html: text && text.replaceAll
              ? text.replaceAll('\n', '<br />')
              : text,
          }}
          variant={{ xs: 'subtitle2', md: 'h6' }}
        />
      </Box>
    </Box>
  );
}

RibbonElement.typeName = 'RibbonElement'; // Strapi element type
RibbonElement.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.shape({
    text: PropTypes.string.isRequired,
    theme: PropTypes.string,
    position_y: PropTypes.number,
  }).isRequired,
};
RibbonElement.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  data: {
    theme: 'primary',
    position_y: 80,
  },
};

RibbonElement.graphQlSchema = `
  id
  text
  theme
  positionY
`;
