import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  useMediaQuery,
} from '@mui/material';
import PropTypes from 'prop-types';
import NextLink from '@components/atoms/Link';
import { useTranslation } from 'next-i18next';
import Image from '@components/atoms/Image2';
import Select from '../atoms/Select';
import { LOCALES } from '@/constants';

export default function LanguagePopover(props) {
  const {
    anchorEl,
    onClose,
    handleOpen,
    locales,
    pageUrls,
    open,
    sx,
    variant = 'mobile',
    ...otherProps
  } = props;
  const { i18n } = useTranslation();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const content = locales.map((item) => (
    <NextLink
      key={item.attributes.code}
      href={
        pageUrls.find((url) => url.locale === item.attributes.code)?.url || '/'
      }
      legacyBehavior
      locale={item.attributes.code}
      passHref
      value={item.attributes.code}
    >
      <MenuItem
        component="a"
        dense={!mdUp}
        onClick={onClose}
      >
        <ListItemIcon>
          <Image
            alt={item.attributes.name}
            height={36}
            src={LOCALES[item.attributes.code]?.icon}
            style={{
              display: 'block',
              height: 20,
              width: 20,
              objectFit: 'contain',
            }}
            width={36}
          />
        </ListItemIcon>
        <ListItemText
          primary={item.attributes.name}
          primaryTypographyProps={{
            variant: 'subtitle2',
            component: 'span',
          }}
        />
      </MenuItem>
    </NextLink>
  ));

  if (variant === 'desktop') {
    return (
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        onClose={onClose}
        open={open}
        sx={sx}
        transitionDuration={0}
        {...otherProps}
      >
        {content}
      </Popover>
    );
  }

  return (
    <Box sx={sx}>
      <Select
        fullWidth
        onClose={onClose}
        onOpen={handleOpen}
        open={open}
        value={i18n.language}
        variant="outlined"
        {...otherProps}
      >
        {content}
      </Select>
    </Box>
  );
}

LanguagePopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
