import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'next-i18next';
import useContentElement from '../../hooks/useContentElement';

export default function LinkElement(props) {
  const {
    data, children, onClick, ...otherProps
  } = props;
  // const { i18n } = useTranslation();
  const { elementData } = useContentElement(
    data,
    LinkElement.dataSchema,
  );

  const { isNewWindow } = elementData;
  const url = elementData.resolvedUrl ?? '#';
  let rel = null;

  if (url && url.startsWith('http')) {
    rel = 'noopener noreferrer';
  }

  /* const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }

    if (e.defaultPrevented) {
      return;
    }

    e.preventDefault();
    window.location.href = url;
  }; */

  return (
    <Link
      href={url}
      legacyBehavior
      passHref
      {...otherProps}
    >
      {React.cloneElement(children, {
        target: isNewWindow ? '_blank' : undefined,
        rel,
      })}
    </Link>
  );
}

function getUrl(url, i18n) {
  return `${i18n.language === 'de' ? '' : `/${i18n.language}`}${url}`;
}

LinkElement.typeName = 'LinkElement'; // Strapi element type
LinkElement.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string,
    resolvedUrl: PropTypes.string,
    isNewWindow: PropTypes.bool,
  }),
  children: PropTypes.node,
};
LinkElement.defaultProps = {
  data: {
    isNewWindow: false,
  },
};

LinkElement.graphQlSchema = `
  id
  url
  query
  hash
  resolvedUrl
  isNewWindow
`;
