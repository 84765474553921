import { Fab, IconButton, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { FiTool } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { trackEvent } from '@components/organisms/Tracking';
import { useState, useEffect } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { zIndices } from '@/theme';

export default function WorkshopFab(props) {
  const {
    data,
    iconButton,
  } = props;
  const { t } = useTranslation();
  const router = useRouter();
  const [isDismissed, setDismissed] = useState(null);

  useEffect(() => {
    setDismissed(getState());
  }, []);

  const {
    sidebarUrlWorkshop,
  } = data ?? {};

  const handleClick = () => {
    trackEvent('Contact');
    trackEvent('ContactCustom', {
      channel: 'WorkshopAppointment',
      origin: 'Workshop Floating Action Bar',
    });
    router.push(sidebarUrlWorkshop ?? '#');
  };

  const handleToggleState = () => {
    if (getState()) {
      resetState();
    } else {
      setState();
    }

    setDismissed(getState());
  };

  if (iconButton) {
    return (
      <IconButton
        aria-label="Workshop"
        onClick={handleClick}
      >
        <FiTool
          style={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
    );
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        right: '110px',
        bottom: 82,
        zIndex: zIndices.workshopFab,
        display: {
          xs: 'none',
          md: isDismissed ? 'none' : 'flex',
        },
      }}
    >
      <Fab
        aria-label="Workshop"
        color="primary"
        onClick={handleClick}
        sx={{
          flexDirection: 'column',
          textTransform: 'unset',
          height: '90px',
          paddingRight: '32px',
          paddingLeft: '32px',
        }}
        variant="extended"
      >
        <Box mt={1}>
          <FiTool style={{ width: 30, height: 30 }} />
        </Box>
        <Box>
          {t('components.molecules.WorkshopFab.text')}
        </Box>
      </Fab>
      <IconButton
        onClick={handleToggleState}
        size="small"
        sx={{
          position: 'absolute',
          top: -10,
          right: -10,
          background: '#eee',
          zIndex: zIndices.workshopFab + 1,
        }}
      >
        <IoCloseSharp style={{ width: 20, height: 20 }} />
      </IconButton>
    </Box>
  );
}

WorkshopFab.propTypes = {
  iconButton: PropTypes.bool,
  data: PropTypes.object,
};

const STORAGE_KEY = 'workshop-fab-dismissed';

function getState() {
  const itemStr = window.localStorage.getItem(STORAGE_KEY);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    window.localStorage.removeItem(STORAGE_KEY);
    return null;
  }

  return item.value;
}

function setState() {
  const now = new Date();
  const item = {
    value: true,
    expiry: now.getTime() + 1000 * 60 * 60 * 24,
  };
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(item));
}

function resetState() {
  window.localStorage.removeItem(STORAGE_KEY);
}
